
import {
  Vue,
  Component,
  Provide,
  ProvideReactive,
} from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import reservation from '@/services/reservation'
import cancellation from '@/services/cancellation'
import auth from '@/store/modules/auth'
import { ReservationDetail, ClientCancellationPenaltyInfo } from '@/models/dto'
import ReservationCancelError from '@/components/ReservationCancelError.vue'

@Component({ components: { Main, ReservationCancelError } })
export default class ReservationCancel extends Vue {
  reservationId: number = null
  reservation: ReservationDetail = null
  clientCancellationInfo: ClientCancellationPenaltyInfo = {
    amount: null,
    paid: null,
    cancellationFee: null,
    balanceDue: null,
    refundPolicyPercent: null,
    refundPolicyPercentValidUntilTime: null,
    refundPolicy: [],
  }

  @Provide('isInReservationCancel') isInReservationCancel = true
  @ProvideReactive('isSubmitting') isSubmitting = false

  hasError = false
  reasonId = 0
  otherDescription = ''
  stepIndex = 0
  steps = [
    {
      header: 'Cancel your reservation',
      component: () => import('@/components/ReservationCancelReason.vue'),
    },
    {
      header: 'Confirm your cancellation',
      component: () => import('@/components/ReservationCancelConfirm.vue'),
    },
    {
      header: 'Your reservation was cancelled',
      component: () => import('@/components/ReservationCancelComplete.vue'),
    }
  ]

  get step(): any {
    return this.steps[this.stepIndex]
  }

  get component(): any {
    return this.step?.component
  }

  get header(): string {
    return this.step?.header
  }

  get isReservationOwnedByUser(): boolean {
    return this.reservation.customer?.customerId === auth.userId
  }

  async created(): Promise<void> {
    this.reservationId = parseInt(this.$route.params.id)

    const requests = [this.getReservation(), this.getCancellationPenaltyInfo()]
    await Promise.all(requests)

    try {
      const reservationResponse = await reservation.byId(this.reservationId)
      this.reservation = reservationResponse?.data?.data
    } catch (e) {
      console.error(e)
    }

    const { isSelfServeCancellable } = this.reservation || {}

    if (
      !this.isReservationOwnedByUser ||
      !this.reservationUpcomingOrOnHold ||
      !isSelfServeCancellable
    ) {
      this.$router.push({
        name: 'reservation-detail',
        params: { id: this.reservationId.toString() },
      })
      return
    }
  }

  async getReservation(): Promise<void> {
    const reservationId = parseInt(this.$route.params.id)
    if (!reservationId || isNaN(reservationId)) {
      this.$router.push({ name: 'home' })
    }
    const reservationResponse = await reservation.byId(reservationId)
    this.reservation = reservationResponse.data.data
  }

  async getCancellationPenaltyInfo(): Promise<void> {
    const reservationId = parseInt(this.$route.params.id)
    const info = await cancellation.getCancellationPenaltyInfo(reservationId)
    this.clientCancellationInfo = info?.data
  }

  isUserBookingContact(): boolean {
    const bookingContactId = this.reservation.customer.customerId
    return bookingContactId === auth.userId
  }

  get reservationUpcomingOrOnHold(): boolean {
    return (
      this.reservation?.reservationStatus === 'upcoming' ||
      this.reservation?.reservationStatus === 'hold'
    )
  }

  nextStep(): void {
    if (this.stepIndex >= this.steps.length - 1) {
      return
    }
    this.stepIndex++
  }

  previousStep(): void {
    if (this.stepIndex <= 0) {
      return
    }
    this.stepIndex--
  }

  async toReservationDetail(): Promise<void> {
    await this.$router.push({
      name: 'reservation-detail',
      params: { id: this.reservationId.toString() },
    })
  }

  async handleCancel(): Promise<void> {
    if (this.isSubmitting) {
      return
    }

    this.isSubmitting = true
    try {
      const payload = {
        reservationId: this.reservationId,
        classificationId: this.reasonId,
        comments: this.otherDescription,
      }
      await reservation.cancel(payload)
      this.nextStep()
    } catch (e) {
      this.hasError = true
      console.error(e)
    }
    this.isSubmitting = false
  }
}
